import React from "react";
import {convertPrice} from "../../utils/helper.service";

const OrdersList = (props) => {

  return (

      props.orders.length === 0 ? <div className={'notification'}>Vēl nav neviena piesaistītā klienta pasūtījuma.</div> :

      <div className={'box'}>
        <div className="list">

          {props.orders.map(order => (

                          <div key={order.id} className="list-item">
                              <div className="list-item-content">
                                  <div className="list-item-title">Jūsu peļņa: <b>{convertPrice(order.profit_in_cents)} </b></div>
                                  <div className="list-item-description">
                                      <div className="tag is-rounded">Pasūtījuma vērtība: <b>{convertPrice(order.price_in_cents_with_vat)}</b></div>
                                      <div className="tag is-rounded ml-1">Izpildīts: <b>{order.finished_at}</b></div>
                                      <div className="tag is-rounded ml-1">Klients: <b>{order.phone_with_code}</b></div>
                                  </div>
                              </div>
                          </div>

          ))}


        </div>
      </div>
  );
};

export default OrdersList;