import * as React from "react"
import {navigate} from "gatsby"
import {useEffect, useState} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import {isLoggedIn} from "../utils/auth.service";
import LoadingSpinner from "../components/loading-spinner";
import authHeader from "../utils/auth-header";
import OrdersList from "../components/orders-list/list";


const CustomerOrders = () => {

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {

    if(!isLoggedIn()){
     return navigate('/');
    }

      loadOrders();


  }, [])


    const loadOrders = () => {


        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };


        fetch(process.env.WASHCAR_API_URL + "/partners/my-attracted-clients-order-history", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
               setOrders(resultData.data.orders);
                setLoading(false);
            }) //
    }



  return (
    <Layout>
      <Seo title="Piesaistīto klientu pasūtījumi" />
      <h1 className="title">Piesaistīto klientu pasūtījumi</h1>
      {loading ? <div className="pos-center"><LoadingSpinner /></div> : <OrdersList orders={orders} />}
    </Layout>
  )
}

export default CustomerOrders
