
const helperService = {

};

export const convertPrice = (amount) => {

  let eur = amount / 100;
  return  eur.toLocaleString("en-US", {style:"currency", currency:"EUR"});

}

export const formatTime = (my_date) => {

  let d = new Date(my_date);

  return d.getDate()  + "." + (d.getMonth()+1) + "." + d.getFullYear();


}


export default helperService;



